import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, Button, DivBlock } from "../modules/Basic";
import { Heading, Span } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import {
  handleAdminReportDate,
  handleDate,
  startPageTimer,
  trackPageDuration,
} from "../utils/function";
import { FormBlock, Label, TextInput } from "../modules/Forms";
import { If, Then } from "../modules/Logic";
import { Image } from "../modules/Media";
import ImageCrop from "../modules/ImageCrop";

class AdminSchoolReportPAge extends Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.state = {
      startTime: null,
      changeImage: null,
      errorMessage: "",
      setCropper: "",
      image: "",
      imageName: "",
      width: 0,
      imageCrop: 300,
      imageURl: "",
    };
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  componentDidMount() {
    startPageTimer("School Report");
    this.setState({ startTime: Date.now() });
    this.props.getSchoolReport();
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.adminEditUser !== this.props.adminEditUser) {
      this.setState({
        changeImage: this.props?.adminEditUser?.school_logo
          ? process.env.REACT_APP_PROFILE +
            this.props?.adminEditUser?.school_logo?.medium
          : null,
      });
    }
  }

  componentWillUnmount() {
    trackPageDuration("School Report", this.state.startTime);
  }

  handleChange = (e) => {
    e.preventDefault();
    let files;
    if (e.target) {
      var img = document.createElement("img");
      var that = this;
      files = e.target.files;
      this.setState({ imageName: e.target.files?.[0]?.name });
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onloadend = (ended) => {
      img.src = ended.target.result;
      img.onload = function () {
        that.setState({ width: this.width });
        if (this.width >= 300) {
          if (this.width >= 600) {
            that.setState({ imageCrop: 600 });
          }
          that.setState({
            changeImage: reader.result,
            modal: true,
            errorMessage: "",
          });
        } else {
          that.setState({
            errorMessage: "Image resolution should be more than 300px.",
          });
        }
      };
    };
  };

  setCanvasAndCropBoxData = () => {
    const { setCropper } = this.state;
    if (setCropper) {
      const imageData = setCropper.getImageData();

      // Ensure the crop box height is 100% of the image height
      const cropBoxHeight = imageData.height;
      const cropBoxWidth = cropBoxHeight; // for 1:1 aspect ratio

      // Calculate the positions to center the crop box within the image
      const cropBoxLeft = (imageData.width - cropBoxWidth) / 2;
      const cropBoxTop = (imageData.height - cropBoxHeight) / 2;

      // Set the crop box data to be centered and maintain 1:1 aspect ratio
      setCropper.setCropBoxData({
        left: cropBoxLeft,
        top: cropBoxTop,
        width: cropBoxWidth,
        height: cropBoxHeight,
      });

      // Set the canvas data to center the image within the container
      setCropper.setCanvasData({
        left: (setCropper.getContainerData().width - imageData.width) / 2,
        top: (setCropper.getContainerData().height - imageData.height) / 2,
        width: imageData.width,
        height: imageData.height,
      });
    }
  };

  handleCropperInitialized = (instance) => {
    this.setState({ setCropper: instance }, () => {
      // Delay setting canvas and crop box data to ensure the cropper is fully initialized
      setTimeout(() => {
        this.setCanvasAndCropBoxData();
      }, 100); // Adjust the delay as needed
    });
  };

  handleProfileLogoCancel = () => {
    const { dispatch } = this.props;
    this.setState({
      modal: false,
      changeImage: this.props?.adminEditUser?.school_logo
        ? process.env.REACT_APP_PROFILE +
          this.props?.adminEditUser?.school_logo?.medium
        : null,
      image: "",
      errorMessage: "",
    });
    dispatch({
      type: "REMOVE_ADMIN_EDIT_USER_ERROR",
    });
    if (this.imageRef?.current) {
      this.imageRef.current.value = "";
    }
  };

  handleAdminEditUser = () => {
    const { dispatch } = this.props;
    dispatch({
      type: "SET_ADMIN_EDIT_USER",
      inputVariables: { editUser: {} },
    });
    dispatch({
      type: "REMOVE_ADMIN_EDIT_USER_ERROR",
    });
    this.setState({
      errorMessage: "",
    });
  };

  getCropData = () => {
    if (this.state.setCropper !== "undefined") {
      this.setState({
        changeImage: this.state.setCropper
          .getCroppedCanvas({
            width: this.state.imageCrop,
            height: this.state.imageCrop,
          })
          .toDataURL(),
        modal: false,
      });
    }

    const imageData = this.dataURLtoFile(
      this.state.setCropper
        .getCroppedCanvas({
          width: this.state.imageCrop,
          height: this.state.imageCrop,
        })
        .toDataURL(),
      this.state.imageName
    );
    this.setState({
      image: imageData,
      changeImage: URL.createObjectURL(imageData),
    });
  };

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl?.split(","),
      mime = arr?.[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr?.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  handleButtonClick = () => {
    this.updateAdminEditUser();
    this.setState({ image: "", errorMessage: "" });
  };

  updateAdminEditUser = () => {
    const { image } = this.state;
    const { dispatch } = this.props;
    dispatch({
      type: "UPDATE_ADMIN_EDIT_USER",
      inputVariables: { image: image },
    });
  };

  render() {
    let {
      NavBarDropDownIndex,
      schoolReport,
      isLoaded,
      sortingSchoolReport,
      adminEditUser,
      FormAdminEditUserErrors,
    } = this.props;

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 2 : 2,
              },
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent />
            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"}>
                      <Heading className={"h2"} style={{}} type={"h2"}>
                        School Report
                      </Heading>

                      <DivBlock className="table-responsive-sm">
                        <table className={"table"}>
                          <thead>
                            <tr className={"thead-bg"} style={{}}>
                              <th className={"th-border"}>
                                {" "}
                                <Span className={"th-span"}>
                                  {"School Image"}
                                </Span>
                              </th>
                              <th className={"th-border"}>
                                <Span
                                  className={"Sorting-span th-span"}
                                  actions={[
                                    {
                                      action: "Sort Admin Report Table",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        sortingField: "created",
                                        sortingType: "date",
                                        inputSorting:
                                          sortingSchoolReport !== true,
                                      },
                                    },
                                  ]}
                                >
                                  Date Created
                                </Span>
                              </th>

                              <th className={"th-border"}>
                                <Span
                                  className={"Sorting-span th-span"}
                                  actions={[
                                    {
                                      action: "Sort Admin Report Table",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        sortingField: "name",
                                        sortingType: "string",
                                        inputSorting:
                                          sortingSchoolReport !== true,
                                      },
                                    },
                                  ]}
                                >
                                  School
                                </Span>
                              </th>

                              <th className={"th-border"}>
                                <Span
                                  className={"Sorting-span th-span"}
                                  actions={[
                                    {
                                      action: "Sort Admin Report Table",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        sortingField: "total_coaches",
                                        sortingType: "number",
                                        inputSorting:
                                          sortingSchoolReport !== true,
                                      },
                                    },
                                  ]}
                                >
                                  Total Coaches
                                </Span>
                              </th>

                              <th className={"th-border"}>
                                <Span
                                  className={"Sorting-span th-span"}
                                  actions={[
                                    {
                                      action: "Sort Admin Report Table",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        sortingField: "total_players",
                                        sortingType: "number",
                                        inputSorting:
                                          sortingSchoolReport !== true,
                                      },
                                    },
                                  ]}
                                >
                                  Total Players
                                </Span>
                              </th>
                              <th className={"th-border"}>
                                <Span
                                  className={"Sorting-span th-span"}
                                  actions={[
                                    {
                                      action: "Sort Admin Report Table",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        sortingField: "total_games_created",
                                        sortingType: "number",
                                        inputSorting:
                                          sortingSchoolReport !== true,
                                      },
                                    },
                                  ]}
                                >
                                  Total Games Created
                                </Span>
                              </th>
                              <th className={"th-border"}>
                                <Span
                                  className={"Sorting-span th-span"}
                                  actions={[
                                    {
                                      action: "Sort Admin Report Table",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        sortingField: "total_practices_created",
                                        sortingType: "number",
                                        inputSorting:
                                          sortingSchoolReport !== true,
                                      },
                                    },
                                  ]}
                                >
                                  Total Practices Created
                                </Span>
                              </th>
                            </tr>
                          </thead>

                          <tbody
                            style={{
                              objectFit: "contain",
                              overflow: "visible",
                            }}
                          >
                            {!isLoaded ? (
                              schoolReport?.result?.length > 0 ? (
                                isArray(schoolReport?.result) &&
                                schoolReport?.result.map((row, index) => {
                                  return (
                                    <tr key={row?.school}>
                                      <td
                                        className="school-report-table"
                                        style={{
                                          alignItems: "center",
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {row?.school_logo ? (
                                          <Image
                                            src={
                                              row?.school_logo
                                                ? process.env
                                                    .REACT_APP_PROFILE +
                                                  row?.school_logo?.thumbnail
                                                : ""
                                            }
                                            style={{
                                              height: "35px",
                                              width: "35px",
                                              borderRadius: "50%",
                                            }}
                                          />
                                        ) : null}
                                      </td>
                                      <td className="school-report-table">
                                        <Span className={"table-span"}>
                                          {handleDate(
                                            row["created"]
                                          )}
                                        </Span>
                                      </td>

                                      <td className="school-report-table">
                                        <Span
                                          className={"btn table-span"}
                                          style={{
                                            textDecoration: "underline",
                                          }}
                                          actions={[
                                            {
                                              action: "Set Admin Edit User",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                editUser: row,
                                              },
                                            },
                                          ]}
                                        >
                                          {row["name"]}
                                        </Span>
                                      </td>

                                      <td className="school-report-table">
                                        <Span className={"table-span"}>
                                          {row["total_coaches"]}
                                        </Span>
                                      </td>

                                      <td className="school-report-table">
                                        <Span className={"table-span"}>
                                          {row["total_players"]}
                                        </Span>
                                      </td>
                                      <td className="school-report-table">
                                        <Span className={"table-span"}>
                                          {row["total_games_created"]}
                                        </Span>
                                      </td>
                                      <td className="school-report-table">
                                        <Span className={"table-span"}>
                                          {row["total_practices_created"]}
                                        </Span>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <Span
                                  className={"table-span"}
                                  style={{ fontSize: "20px" }}
                                >
                                  No data found
                                </Span>
                              )
                            ) : null}
                          </tbody>
                          <tfoot>
                            {!isLoaded && schoolReport?.result?.length > 0 ? (
                              <tr className="thead-bg school-report">
                                <td className="th-border"></td>
                                <td className="th-border">
                                  <Span className={"th-span"}>{"Total"}</Span>
                                </td>
                                <td className="th-border">
                                  <Span className={"th-span"}>
                                    {schoolReport?.result?.length}
                                  </Span>
                                </td>
                                <td className="th-border">
                                  <Span className={"th-span"}>
                                    {schoolReport?.total?.total_coaches}
                                  </Span>
                                </td>
                                <td className="th-border">
                                  <Span className={"th-span"}>
                                    {schoolReport?.total?.total_players}
                                  </Span>
                                </td>
                                <td className="th-border">
                                  <Span className={"th-span"}>
                                    {schoolReport?.total?.total_games_created}
                                  </Span>
                                </td>
                                <td className="th-border">
                                  <Span className={"th-span"}>
                                    {
                                      schoolReport?.total
                                        ?.total_practices_created
                                    }
                                  </Span>
                                </td>
                              </tr>
                            ) : null}
                          </tfoot>
                        </table>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                  <If
                    condition={
                      adminEditUser && Object.keys(adminEditUser)?.length > 0
                        ? true
                        : false
                    }
                    show={false}
                  >
                    <Then>
                      <DivBlock className={"modal"}>
                        <DivBlock className={"modal-dialog"}>
                          <DivBlock className={"modal-dialog"}>
                            <DivBlock className={"modal-content"}>
                              <DivBlock className={"modal-header"}>
                                <Heading className="modal-heading" type={"h5"}>
                                  Edit School Report
                                </Heading>
                                <span
                                  onClick={this.handleAdminEditUser}
                                  className={"close"}
                                >
                                  x
                                </span>
                              </DivBlock>

                              <DivBlock className={"modal-body"}>
                                <FormBlock
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}
                                >
                                  <DivBlock className={"form-group"}>
                                    <Label className={"label"}>
                                      School Name
                                    </Label>

                                    <TextInput
                                      className={"form-control"}
                                      enableReinitialize={true}
                                      initialValue={
                                        adminEditUser
                                          ? adminEditUser["name"]
                                          : ""
                                      }
                                      name={"FormAdminEditUser.name"}
                                      placeholder={""}
                                    ></TextInput>

                                    <If
                                      condition={
                                        FormAdminEditUserErrors &&
                                        FormAdminEditUserErrors["name"]
                                          ? true
                                          : false
                                      }
                                      show={true}
                                    >
                                      <Then>
                                        <Span className={"invalid-feedback"}>
                                          {FormAdminEditUserErrors
                                            ? FormAdminEditUserErrors["name"]
                                            : ""}
                                        </Span>
                                      </Then>
                                    </If>
                                  </DivBlock>
                                  <DivBlock>
                                    <DivBlock
                                      className={"form-group"}
                                      style={{ marginTop: "15px" }}
                                      onClick={() =>
                                        this.imageRef.current.click()
                                      }
                                    >
                                      <Label className={"label"}>
                                        School Logo
                                      </Label>
                                      {adminEditUser?.school_logo?.medium ? (
                                        <>
                                          <Image
                                            src={this.state.changeImage}
                                            style={{
                                              height: "150px",
                                              width: "150px",
                                              cursor: "pointer",
                                            }}
                                          />
                                          <Span className={"invalid-feedback"}>
                                            {this.state.errorMessage
                                              ? this.state.errorMessage
                                              : "Click image above to edit."}
                                          </Span>
                                        </>
                                      ) : null}
                                    </DivBlock>
                                    {adminEditUser?.school_logo?.medium ? (
                                      <DivBlock className="remove-input">
                                        <input
                                          type="file"
                                          key={this.state.changeImage || ""}
                                          ref={this.imageRef}
                                          onChange={(e) => this.handleChange(e)}
                                          accept=".jpg, .jpeg, .png"
                                        />
                                      </DivBlock>
                                    ) : (
                                      <DivBlock
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <input
                                          type="file"
                                          key={this.state.changeImage || ""}
                                          ref={this.schoolLogoRef}
                                          onChange={(e) => this.handleChange(e)}
                                          accept=".jpg, .jpeg, .png"
                                        />
                                        <Span className={"invalid-feedback"}>
                                          {this.state.errorMessage}
                                        </Span>

                                        {this.state.changeImage &&
                                        !adminEditUser?.school_logo?.medium ? (
                                          <>
                                            <div
                                              style={
                                                this.state.changeImage
                                                  ? {
                                                      display: "flex",
                                                      marginTop: "10px",
                                                    }
                                                  : { display: "none" }
                                              }
                                            >
                                              <img
                                                src={this.state.changeImage}
                                                style={{
                                                  height: "150px",
                                                  width: "150px",
                                                  cursor: "pointer",
                                                }}
                                                alt={"Logo"}
                                              />
                                            </div>
                                          </>
                                        ) : null}
                                      </DivBlock>
                                    )}
                                  </DivBlock>

                                  <button
                                    className={"btn btn-primary"}
                                    style={{ alignSelf: "flex-start" }}
                                    onClick={this.handleButtonClick}
                                  >
                                    Submit
                                  </button>
                                </FormBlock>
                              </DivBlock>
                            </DivBlock>
                          </DivBlock>
                        </DivBlock>
                      </DivBlock>
                    </Then>
                  </If>

                  <If condition={this.state.modal ? true : false} show={false}>
                    <Then>
                      <DivBlock className={"modal"}>
                        <DivBlock className={"modal-dialog"}>
                          <DivBlock className={"modal-dialog"}>
                            <DivBlock className={"modal-content"}>
                              <DivBlock className={"modal-header"}>
                                <Heading className="modal-heading" type={"h5"}>
                                  Crop Image Before Upload
                                </Heading>

                                <span
                                  className={"close"}
                                  onClick={this.handleProfileLogoCancel}
                                >
                                  x
                                </span>
                              </DivBlock>
                              <DivBlock
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  overflow: "auto",
                                }}
                              >
                                <DivBlock style={{ width: "70%" }}>
                                  <ImageCrop
                                    initialAspectRatio={1 / 1}
                                    // aspectRatio
                                    preview=".img-preview"
                                    guides={true}
                                    src={this.state.changeImage}
                                    onInitialized={
                                      this.handleCropperInitialized
                                    }
                                    viewMode={1}
                                    dragMode="move"
                                    cropBoxMovable={false}
                                    checkOrientation={false}
                                    toggleDragModeOnDblclick={false}
                                    modal
                                    background={false}
                                    minCropBoxHeight={50}
                                    minCropBoxWidth={50}
                                  />
                                </DivBlock>
                                <DivBlock
                                  style={{ width: "30%", marginLeft: "10px" }}
                                >
                                  <DivBlock
                                    className="img-preview"
                                    style={{ width: "100%", height: "300px" }}
                                  />
                                </DivBlock>
                              </DivBlock>
                              <DivBlock style={{ marginLeft: "10px" }}>
                                <Span className={"invalid-feedback"}>
                                  Note : Zoom in or zoom out for cropping the
                                  image.
                                </Span>
                              </DivBlock>
                              <DivBlock
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginRight: "20px",
                                }}
                              >
                                <button
                                  className={"btn btn-reset DeleteBtn"}
                                  onClick={this.handleProfileLogoCancel}
                                >
                                  Cancel
                                </button>

                                <button
                                  className={"btn btn-primary"}
                                  onClick={this.getCropData}
                                >
                                  Crop
                                </button>
                              </DivBlock>
                            </DivBlock>
                          </DivBlock>
                        </DivBlock>
                      </DivBlock>
                    </Then>
                  </If>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSchoolReport: () => {
      dispatch({ type: "GET_SCHOOL_REPORT" });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminSchoolReportPAge);
